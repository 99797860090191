import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`Sonxai`}</strong>{` are a criminal organization/cult primarily run by `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Deep Dwellers`}</a>{` in the city of `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      